import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {getClassNames, getImage, wpContentParse} from '../util'
import { Input, Textarea, Select } from '../components/form'
import { useForm } from "react-hook-form";
import { sendForm } from '../services/reedmaker'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import CaptchaLegal from "../components/CaptchaLegal"


const ReviewForm = () => {
    const { executeRecaptcha } = useGoogleReCaptcha()
    const { register, handleSubmit, errors } = useForm();
    const [submitted, setSubmitted] = useState(false)
    const [submitError, setSubmitError] = useState(false)

    /**
     * Go to top of Page on Submit
     */
    useEffect(() => {
      if (submitted) {
        window.scrollTo(0, 0);
      }
    }, [submitted]);

    const onSubmit = async (data) => { 
      // Check if the captcha was skipped or not
      if (!executeRecaptcha) {
        return
      }

      // Add Captcha Token to request
      const result = await executeRecaptcha('reviewForm')
      data['token'] = result

      // Form
      const success = await sendForm(data)
      setSubmitted(true)
      setSubmitError(!success)
    };

    if (submitted && !submitError) {
      return (
        <>
        
          <h2 className="text-5xl font-bold leading-tight text-center text-green-600">
            Thank you for your submission.
          </h2>
        </>
      )
    }

    return (
        <form className="md:flex" onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full py-6 px-2">

            <div className="md:flex -mx-3">
                <div className={`md:w-1/2 mb-3 md:mb-6 px-3`}>
                  <Input
                    name="name"
                    placeholder="Your name"
                    errors={errors}
                    register={register} 
                    required
                  />
                </div>
  
                <div className="md:w-1/2 mb-3 md:mb-6 px-3">
                  <Input
                    name="email"
                    type="email"
                    placeholder="Email address"
                    errors={errors}
                    register={register} 
                    required
                  />
                </div>
              </div>

              <div className="mb-3 md:mb-6">
                <Select
                    name="Product Review"
                    errors={errors}
                    register={register} 
 
                    options={[
                        { value: 'select', name: 'Select a Product'},
                        { value: 'oboeReed', name: 'Oboe Reed'},
                        { value: 'englishHornReed', name: 'English Horn Reed'},
                        { value: 'oboeReedCase', name: 'Oboe Reed Case'},
                        { value: 'other', name: 'Other'},
                    ]}
                />
              </div>

              <div className="mb-3 md:mb-6">
                <Select
                    name="Rating"
                    errors={errors}
                    register={register} 
 
                    options={[
                        { value: 'select', name: 'Select a Rating'},
                        { value: '5', name: '5 Stars'},
                        { value: '4', name: '4 Stars'},
                        { value: '3', name: '3 Stars'},
                        { value: '2', name: '2 Stars'},
                        { value: '1', name: '1 Stars'},
                    ]}
                />
              </div>

              <div className="mb-3 md:mb-6">
                <Input
                  name="telephone"
                  placeholder="Telephone Number" 
                  errors={errors}
                  register={register} 
                />
              </div>
  
              <div className="mb-3 md:mb-6">
                <Input
                  name="order"
                  placeholder="Order Number"
                  errors={errors}
                  register={register} 
                />
              </div>

              <div className="mb-3 md:mb-6">
                <Textarea
                  name="message"
                  placeholder="Review Details"
                  rows={5}
                  errors={errors}
                  register={register}
                />
              </div>

              <div className=" -mt-6 mb-6">
                <CaptchaLegal />
              </div>
  
              <button
                type="submit"
                disabled={submitted && !submitError}
                className="bg-red-800 text-white rounded-lg text-gray px-3 py-2 h-10 focus:outline-none font-bold"
              >
                Submit
              </button>
              
              {(submitted && submitError) && 
                <span>
                  Something went wrong. Can you try again to submit again.
                </span>
              }
            </div>
        </form>
    )
}

const DefaultPage = (props) => {
  const { pageContext } = props
  const { title, acfGlobalPages, featuredImage } = pageContext.node 
  const wpContent = wpContentParse(pageContext)
  const layoutClasses = getClassNames(acfGlobalPages.sidebarLeft, acfGlobalPages.sidebarRight)
  const Image = getImage(featuredImage, pageContext.plugins.wpImage)

  return (
      <Layout>
          <SEO title={title} description={acfGlobalPages.metaDescription} />
          <div className="container mx-auto p-6 py-16">

              <div className="flex flex-wrap">

                  { layoutClasses.sidebarLeft &&
                  <section className={`${layoutClasses.sidebarLeft} p-3 pt-8`}>
                      <div className="md:border-r p-4">
                          <div className="content-block sidebar-left-one">{wpContent.leftOne}</div>
                          <div className="content-block sidebar-left-two">{wpContent.leftTwo}</div>
                      </div>
                  </section>
                  }

                  <section className={`${layoutClasses.main} p-3`}>                  
                      <h1 className="font-bold text-5xl md:text-6xl mb-3 text-primary">
                          {title}
                      </h1>
                      <div id="main-content-block">
                          {Image}
                          <section className="content-block">{wpContent.main}</section>
                          {/* <ReviewForm /> */}
                          <a href="mailto:Kerryoboe@gmail.com" className="text-sm text-gray-600 hover:text-gray-800">
                            Kerryoboe@gmail.com
                          </a>
                      </div>
                  </section>

                  { layoutClasses.sidebarRight &&
                  <section className={`${layoutClasses.sidebarRight} p-3 pt-8`}>
                      <div className="md:border-l p-4">
                          <div className="content-block sidebar-right-one">{wpContent.rightOne}</div>
                          <div className="content-block sidebar-right-two">{wpContent.rightTwo}</div>
                      </div>
                  </section>
                  }

              </div>

          </div>
    </Layout>
  )
}

export default DefaultPage